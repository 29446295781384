import { graphql } from "gatsby";
import React, { useContext } from "react";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import {
  ChannelsButtons,
  Divider,
  GetStarted,
  ResourcesSuggestions,
  SectionWrapper,
} from "../components/sections";
import YouTube from "react-youtube";
import { SiteContext } from "../components/global/site-context";
import { BackToZone } from "../components/back-to-zone";
import getYouTubeID from "get-youtube-id";
import { cn, mapEdgesToNodes } from "../lib/helpers";
import PortableText from "../components/portableText";
import { CardGrid } from "../components/card/card-grid";
import { pageDocPath } from "../../helpers";
import { Button } from "../components/button";
import { ArrowLeft } from "../components/icon/arrow-left";
import { videoMetaData } from "./video-bulk";

import * as styles from "./video.module.css";
import * as postStyles from "../components/post-page.module.css";

export const query = graphql`
  query VideoTemplateQuery($id: String!, $series: [String!]) {
    doc: sanityVideo(id: { eq: $id }) {
      _type
      _id
      status
      title
      slug {
        current
      }
      mainImage {
        ...SanityImage
        alt
      }
      mainVideo {
        ...externalVideo
      }
      mainAudio {
        asset {
          url
        }
      }
      _rawBody(resolveReferences: { maxDepth: 12 })
      duration
      series {
        _type
        customPath
        title
        titlePublic
        slug {
          current
        }
      }
    }
    seriesVideos: allSanityVideo(
      limit: 4
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        id: { ne: $id }
        series: { elemMatch: { _id: { in: $series } } }
        status: { in: ["published", "hidden"] }
      }
    ) {
      edges {
        node {
          ...cardNodeVideo
        }
      }
    }
    seriesVideosBulk: allGoogleSpreadsheetWwWebsiteContentVideos(
      limit: 4
      sort: { fields: [publishDate], order: DESC }
      filter: {
        videoId: { ne: $id }
        status: { eq: "published" }
        series: { in: $series }
      }
    ) {
      edges {
        node {
          videoId
          slug
          status
          series
          title
          playlist
          community
          devRel
          gtm
          publishDate
        }
      }
    }
    seriesPosts: allSanityPost(
      limit: 3
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        categories: { elemMatch: { _id: { in: $series } } }
        status: { eq: "published" }
      }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
    seriesEvents: allSanityEvent(
      limit: 1
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        categories: { elemMatch: { _id: { in: $series } } }
        status: { eq: "published" }
      }
    ) {
      edges {
        node {
          ...cardNodeEvent
        }
      }
    }
  }
`;

const VideoPageTemplate = (props) => {
  const { data = {} } = props;
  const { title, videoId } = data.doc || {};

  return (
    <LayoutContainer doc={data.doc}>
      <PageHeadMeta title={`${title} [video]`} doc={data.doc} />
      <VideoPage
        {...data.doc}
        seriesVideos={mapEdgesToNodes(data.seriesVideos).concat(
          mapEdgesToNodes(data.seriesVideosBulk).map(videoMetaData)
        )}
      />
    </LayoutContainer>
  );
};

export default VideoPageTemplate;

const VideoPage = (props) => {
  const { title, mainVideo, _rawBody, seriesVideos, series } = props;
  const site = useContext(SiteContext);

  const videoId = getYouTubeID(mainVideo.url);
  const seriesPath = series.length > 0 ? pageDocPath(series[0]) : null;
  const seriesTitle =
    series.length > 0 ? series[0].titlePublic || series[0].title : null;

  // @todo Podcast embed

  return (
    <>
      <SectionWrapper container="thin" padding="none">
        <div className="pt-24 mb-12">
          {seriesVideos.length > 0 ? (
            <div className="text-secodary mb-8">
              <Button
                text="Explore series"
                href={seriesPath}
                icon={<ArrowLeft size="12" />}
                bgColor="bg-dark-10"
              />
            </div>
          ) : (
            <BackToZone to="/resources/" text={site.token("backToResources")} />
          )}

          <div className="rounded-xl overflow-hidden">
            <div className={styles.videoWrapper}>
              <YouTube
                videoId={videoId}
                opts={{
                  playerVars: { controls: 0, rel: 0 },
                }}
              />
            </div>
          </div>
          <h1 className="mt-6 mb-2 text-xl md-text-2xl font-semibold">
            {`${title} [video]`}
          </h1>
          <div className={cn("leading-relaxed", postStyles.mainContent)}>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
        </div>
        <Divider />
      </SectionWrapper>

      {seriesVideos.length > 0 ? (
        <SectionWrapper
          layout="centered"
          container="thin"
          header={`${seriesTitle} series`}
        >
          <CardGrid
            maxColumns={2}
            nodes={seriesVideos.slice(0, 4)}
            panels={true}
            hideEvents={true}
            attribution={{ action: "suggestion", label: "video-series" }}
            browseMoreHref={seriesPath}
            browseMoreText="Explore series"
            hideType={true}
            hideDate={true}
            showExtended={true}
          />
        </SectionWrapper>
      ) : (
        <SectionWrapper
          header={site.token("resourcesSuggestions")}
          anchor="more"
          layout="centered"
          container="thin"
        >
          <ResourcesSuggestions exclude={videoId} maxColumns={2} />
        </SectionWrapper>
      )}

      <SectionWrapper container="large" padding="bottom">
        <ChannelsButtons />
      </SectionWrapper>

      <SectionWrapper container="large" padding="bottom">
        <GetStarted />
      </SectionWrapper>
    </>
  );
};
